import { createSignal, ComponentProps, Component, For, Show } from "solid-js";
import { Select, As } from "@kobalte/core";
import { Icon } from "solid-heroicons";
import { check, xMark, chevronDown } from "solid-heroicons/outline";
import Button from "../Button";

type SelectProps = ComponentProps<typeof Select.Root>;
type MultiSelectProps = Partial<
  Pick<SelectProps, "name" | "value" | "onChange" | "placeholder">
> & {
  icon?: any;
  options: string[];
  class?: string;
} & (
    | { hideSelectedPills?: false | undefined }
    | { hideSelectedPills: true; selectedMessage?: (n: number) => string }
  );

export const MultiSelect: Component<MultiSelectProps> = (props) => {
  const [values, setValues] = createSignal(props.value as string[]);
  const [opened, setOpened] = createSignal(false);

  const selectedMessage = (n: number) => {
    if (props.hideSelectedPills) {
      if (props.selectedMessage) {
        return props.selectedMessage(n);
      }
      return `${n} Items Selected`;
    }
  };

  return (
    <>
      <Select.Root<string>
        {...props}
        multiple
        open={opened()}
        value={values()}
        onChange={(val) => {
          setValues(val);
          if (props.onChange) {
            props.onChange(val);
          }
        }}
        class="flex w-full border border-gray-300 shadow-sm  px-1.5 py-2 rounded-md text-gray-400 text-sm placeholder:text-sm hover:border-neutral-400 focus-roma"
        classList={{ [`${props.class}`]: !!props.class }}
        itemComponent={(state) => (
          <Select.Item
            item={state.item}
            class="flex justify-between p-2 hover:bg-gray-100 rounded-lg cursor-pointer hover:text-roma-darkgrey"
          >
            <Select.ItemLabel>{state.item.rawValue}</Select.ItemLabel>
            <Select.ItemIndicator>
              <Icon class="w-4" path={check} />
            </Select.ItemIndicator>
          </Select.Item>
        )}
      >
        <Select.Trigger
          onClick={() => {
            setOpened(!opened());
          }}
          // aria-label="Fruits"
          asChild
          class="flex items-center px-1 w-full"
        >
          <As component="div" class="flex items-center justify-between">
            <Select.Value<string>>
              {(state) => (
                <>
                  <div class="flex gap-1 flex-wrap line-clamp-1">
                    <Show
                      when={props.hideSelectedPills}
                      fallback={
                        <For each={state.selectedOptions()}>
                          {(option) => (
                            <span
                              class="flex bg-gray-200 focus:outline-roma-blue text-xs text-black rounded-2xl px-3 py-1"
                              onPointerDown={(e) => e.stopPropagation()}
                            >
                              {option}
                              <button type="button" onClick={() => state.remove(option)}>
                                <Icon class="w-4" path={xMark} />
                              </button>
                            </span>
                          )}
                        </For>
                      }
                    >
                      <span>
                        {selectedMessage(state.selectedOptions().length)}
                      </span>
                    </Show>
                  </div>
                </>
              )}
            </Select.Value>
            <Select.Icon>
              <Icon
                class="w-5 text-roma-dark-grey"
                path={props.icon ? props.icon : chevronDown}
              />
            </Select.Icon>
          </As>
        </Select.Trigger>
        <Select.Portal>
          <Select.Content class="z-50">
            <Select.Listbox class="border my-1 bg-white focus:outline-roma-blue rounded-lg border-grey-300 p-2 max-h-[30vh] overflow-auto" />
            <div class="flex gap-2 -mt-2 justify-end bg-roma-grey rounded-b-lg border border-roma-gray p-2">
              <div class="flex w-full items-center p-2 text-xs">
                Click the items you'd like to add then continue.
              </div>
              <Button class="w-full text-sm" onClick={() => setOpened(false)}>
                Continue
              </Button>
            </div>
          </Select.Content>
        </Select.Portal>
      </Select.Root>
    </>
  );
};
