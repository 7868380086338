import {
  Component,
  createSignal,
  ComponentProps,
  For,
  Switch,
  Match,
} from "solid-js";
import { Combobox, createFilter } from "@kobalte/core";
import { Icon } from "solid-heroicons";
import {
  check,
  xMark,
  chevronUpDown,
  chevronUp,
  chevronDown,
} from "solid-heroicons/outline";

import "./comboBox.css";


// TODO! Resolve type errors when CustomerSearch/ComboBox is added to admin approve order page.


export type ComboOption = {
  label: string;
  value: string;
  disabled?: boolean;
};

type KComboBoxOptions = ComponentProps<typeof Combobox.Root>;

type ComboBoxOptions = KComboBoxOptions & {
  class?: string;
  options: ComboOption[];
  onChange?: (option: ComboOption[] | []) => void;
  onClear?: VoidFunction;
  style?: "square" | "round";
  multiple?: boolean;
};

export const ComboBox: Component<ComboBoxOptions> = (props) => {
  const [options, setOptions] = createSignal<ComboOption[]>(props.options);
  const [value, setValue] = createSignal();
  const [isOpen, setIsOpen] = createSignal<boolean>();
  const filter = createFilter({ sensitivity: "base" });

  const onOpenChange = (
    isOpen: boolean,
    triggerMode?: Combobox.ComboboxTriggerMode
  ) => {
    // Show all options on ArrowDown/ArrowUp and button click.
    if (isOpen && triggerMode === "manual") {
      setOptions(props.options);
    }
    if (isOpen) {
      setIsOpen(true);
    } else {
      setIsOpen(false);
    }
  };

  const onInputChange = (value: string) => {
    if (value === "") {
      setValue("");
    }
  };

  const onChange = (option: ComboOption[]) => {
    if (props.onChange) props.onChange(option);
    setValue(option);
  };

  return (
    <>
      <Combobox.Root
        options={options()}
        onInputChange={!props.multiple ? onInputChange : undefined}
        onChange={onChange}
        multiple={props.multiple ?? undefined}
        value={value()}
        defaultFilter={(option: ComboOption, input: string) => {
          if (
            option.label?.toUpperCase()?.includes(input.toUpperCase()) ||
            option.value?.toUpperCase()?.includes(input.toUpperCase())
          ) {
            return true;
          }
          return false;
        }}
        onOpenChange={onOpenChange}
        optionValue="value"
        optionTextValue="label"
        optionLabel="label"
        optionDisabled="disabled"
        placeholder={props.placeholder ?? undefined}
        itemComponent={(props) => (
          <Combobox.Item
            item={props.item}
            class="text-sm text-roma-dark-grey rounded-[4px] flex justify-between items-center h-8 px-2 relative select-none outline-none data-[highlighted]:bg-roma-blue data-[highlighted]:text-white data-[disabled]:text-roma-medium-grey data-[disabled]:opacity-50 data-[disabled]:pointer-events-none"
          >
            <Combobox.ItemLabel>{props.item.rawValue.label}</Combobox.ItemLabel>
            <Combobox.ItemIndicator class="combobox__item-indicator">
              <Icon path={check} class="w-4 h-4" />
            </Combobox.ItemIndicator>
          </Combobox.Item>
        )}
      >
        <Switch>
          <Match when={!props.multiple}>
            <Combobox.Control
              class={`border border-gray-300 hover:border-neutral-400 inline-flex justify-between ${
                props.style === "square" ? "" : "rounded-md"
              }  text-sm outline-none bg-white transition-[border-color,_color] w-full`}
            >
              {(state) => (
                <>
                  <Combobox.Input
                    class={`combobox__input w-full appearance-none inline-flex pl-3 text-sm ${
                      props.style === "square" ? "" : "rounded-l-md"
                    } min-h-[40px]`}
                  />
                  <Combobox.Trigger class="border-l appearance-none inline-flex justify-center items-center outline-none border-r-md px-2 text-sm ">
                    <Combobox.Icon class="combobox__icon">
                      <Icon path={chevronDown} class="w-4 h-4" />
                    </Combobox.Icon>
                  </Combobox.Trigger>
                </>
              )}
            </Combobox.Control>
          </Match>
          <Match when={!!props.multiple}>
            <Combobox.Control<{
              label: string;
            }>
              class={`border border-gray-300 hover:border-neutral-400 inline-flex justify-between ${
                props.style === "square" ? "" : "rounded-md"
              }  text-sm outline-none bg-white transition-[border-color,_color] w-full`}
            >
              {(state) => (
                <>
                  <div class={`w-full`}>
                    <For each={state.selectedOptions()}>
                      {(option, index) => (
                        <div
                          class={`inline-flex text-xs items-center bg-roma-blue text-white my-1 rounded-md py-1 px-1.5 ml-2`}
                          classList={{ "mt-2": index() === 0 }}
                          onPointerDown={(e) => e.stopPropagation()}
                        >
                          {option.label}
                          <button onClick={() => state.remove(option)}>
                            <Icon
                              path={xMark}
                              stroke-width={2.5}
                              class="w-4 h-4 ml-1.5 -mr-0.5"
                            />
                          </button>
                        </div>
                      )}
                    </For>
                    <Combobox.Input
                      class={`combobox__input w-full appearance-none inline-flex pl-3 text-sm ${
                        props.style === "square" ? "" : "rounded-l-md"
                      } min-h-[40px]`}
                    />
                  </div>

                  <div class="flex">
                    <button
                      class="px-2"
                      onPointerDown={(e) => e.stopPropagation()}
                      onClick={() => {
                        if (props.onClear) props.onClear();
                        state.clear();
                      }}
                    >
                      <Icon
                        path={xMark}
                        class="w-4 h-4 hover:text-black text-roma-dark-grey"
                      />
                    </button>
                    <Combobox.Trigger class="border-l appearance-none inline-flex justify-center items-center outline-none border-r-md px-2 text-sm ">
                      <Combobox.Icon>
                        <Icon
                          path={isOpen() ? chevronUp : chevronUpDown}
                          class="w-4 h-4"
                        />
                      </Combobox.Icon>
                    </Combobox.Trigger>
                  </div>
                </>
              )}
            </Combobox.Control>
          </Match>
        </Switch>

        {/* MULTIPLE  */}

        <Combobox.Portal>
          <Combobox.Content
            class={`combobox__content bg-white border  border-gray-300 ${
              props.style === "square" ? "" : "rounded-md"
            }`}
          >
            <Combobox.Listbox class="overflow-y-auto max-h-[360px] p-2" />
          </Combobox.Content>
        </Combobox.Portal>
      </Combobox.Root>
    </>
  );
};
